.schema-container {
  height: 100vh;
}

.btn-save {
  position: absolute;
  left: 80px;
  top: 1px;
  z-index: 10;
}

.excalidraw .dropdown-menu {
  position: absolute;
  top: 100%;
  margin-top: 0.25rem;
  display: block;
}

button.new-scheme {
  position: absolute;
  top: 16px;
  left: 67px;
  z-index: 20;
  border: 0;
  padding: 8px;
  border-radius: 6px;
  color: var(--button-color, var(--color-on-surface));
}